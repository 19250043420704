.text{
    margin: 0;
    padding: 0;
    font-size: medium;
    text-align: center;
}

.IDBox{
    position: absolute;
    left: 3%;
    bottom: 5%;
    border-radius: 10px;
    border: solid 1px;
    background: none;
    padding: 1vmin 2%;
}