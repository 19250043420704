.sign_in_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    background-color: var(--secondary-color);
}

form{
    display: flex;
    flex-direction: column;
    background: var(--primary-translucent);
    width: 20vw;
    height: 20vh;
    justify-content: center;
    align-items: center;
    gap: 10%;
    border-radius: 10px;
    padding: 1%;
    z-index: 2;
    position: absolute;
}

#title{
    font-weight: bold;
    font-size: large;
}

label{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vw;
    flex: 1
}

label input{
    margin-left: auto;
    flex: 1;
    min-width: none;
    width: 0;
    max-width: 10vw;
    background-color: transparent !important;
    border: solid 1px var(--secondary-color);
    padding: 2%;
    border-left: none;
    border-top: none;
    border-right: none;
    box-shadow: none;
}

input[type=submit]{
    border: solid 1px var(--secondary-color);
    border-radius: 10px;
    padding: 4%;
    background-color: transparent;
}

input[type=submit]:hover{
    background-color: var(--primary-hover);
}

.background{
    width: 100vw;
    min-height: 100vh;
}