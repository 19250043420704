.dropdown{
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 7.5vh;
    right: .5%;
    align-items: center;
    border: solid 1px var(--secondary-color);
    width: 6.4vw;
    border-radius: 10px;
    padding: 2px;
    background: var(--primary-color);
}

.dropdown_btn{
    border: none;
    background: none;
    text-decoration: none;
    text-align: center;
    flex: 1;
    width: 6.4vw;
    margin: 0;
    box-sizing: border-box;
    border-radius: 10px;
}

.dropdown_btn:hover{
    background-color: var(--primary-hover);
    cursor: pointer;
}