.viewer{
    margin: 2%;
    padding: 2%;
    border-radius: 15px;
    box-shadow: 0 0 .75em var(--secondary-color);
    width: 33%;
    height: 75vh;
    background-color: var(--primary-color);
    flex: 1;
    position: absolute;
    right: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2%;
    overflow: hidden;
}

.title, .text{
    border: solid var(--secondary-color) 1px;
    width: 100%;
    margin: 0;
    flex: 1;
    border-radius: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
}

h1.title{
    align-items: center;
    padding: 0;
    flex: 4 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.text{
    flex: 5;
    overflow-y: auto;
    padding: 2%;
    box-sizing: border-box;
}

.cv_btn{
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    flex: 1;
    height: fit-content;
    border-radius: 5px;
    font-size: larger;
    display: flex;
    align-items: center;
    justify-content: center;
}

.push{
    border: solid blue 1px;
    padding: 1%;
    font-size: 1vw;
    height: 100%;
}

.push:active{
    transform: scale(0.98);
}

.cv_btn:hover{
    background-color: var(--primary-hover);
    cursor: pointer;
}

.top{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 95%;
    align-items: center;
}

.small_top{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 86%;
    gap: 5%;
    align-items: center;
}

::-webkit-scrollbar {
    width: 1vw;
  }

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
    background: var(--secondary-color); 
    border-radius: 15px;
    width: 1vw;
    border: .3rem solid var(--primary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-hover); 
}

.content{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    margin: 0;
    padding: 0;
    gap: 1%;
}

.add, .remove{
    width: 50%;
    flex: none;
}

.remove{
    margin-right: auto;
}

.add{
    margin-left: auto;
}

.top_right, .top_middle, .top_left{
    display: flex;
    flex-direction: row;
    gap: 5%;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.top_left{
    justify-content: flex-end;
    gap: 0;
}

.top_middle{
    margin-left: auto;
    margin-right: auto;
    flex: 2;
}