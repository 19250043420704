.nav_bar{
    display: flex;
    margin: 0%;
    margin-bottom: auto;
    height: 7vh;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    background-color: var(--primary-color);
    border-bottom: solid var(--secondary-color);
}


#logo_div{
    flex: 0;
    display: flex;
    margin: 0;
    height: 100%;
    padding: 0;
    align-items: center;
    justify-content: center;
}

.logo{
    flex: 0;
    margin: 0;
    height: 6.5vh;
    padding: 0;
    align-items: center;
    border: solid var(--primary-color);
    border-radius: 10px;
    box-sizing: border-box;
}

.btn{
    flex: 0 0 auto;
    margin: 0 .5vw;
    text-align: center;
    height: fit-content;
    width: fit-content;
    color: var(--primary-text);
    padding: 0;
    background: none;
    border: none;
    border-radius: 5px;
}

.btn:hover{
    background-color: var(--primary-hover);
    cursor: pointer;
}

#current_title{
    flex: 1;
    padding: 0;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
}

.markdown{
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.div{
    flex: 1;
    width: 1px;
    display: flex;
    align-items: center;
}

.right{
    justify-content: flex-end;
}