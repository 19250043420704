html{
    --primary-color: rgb(248, 247, 240) ;
    --primary-hover: rgb(200, 200, 200);
    --primary-translucent: rgb(248, 247, 240, .6);
    --secondary-color: rgb(139, 135, 135);
    --secondary-hover: rgb(100, 100, 100);
    --alt-color: rgb(99, 125, 202);
    --alt-hover: rgb(99, 100, 202);
    --primary-text: black;
    --secondary-text: white;
}

.callout-note{
    --callout-color: hsl(0, 0%, 0%, 0.6) !important;
}