#arrange_popup{
    left: .5%;
}

#arrange_popup button{
    text-align: left;
}

#save{
    border: solid 1px var(--alt-color);
}

#save:active{
    transform: scale(0.98);
}