.container{
    z-index: 1;
    position: absolute; 
    width: 30vw;
    height: 10vw;
    background: var(--primary-color);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0%;
    flex-direction: column;
    left: 35vw;
    top: 38vh;
    right: auto;
    bottom: auto;
}

.btns{
    display: flex;
    gap: 5%;
    border: solid 1px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}

.btns button{
    padding: 2%;
    background: none;
    max-height: 100%;
    height: 100%;
    max-width: fit-content;
    border-radius: 10px;
    box-shadow: none;
    border: none;
    flex: 1;
    margin: 0 1%;
    font-size: 2vmin;
}

.btns button:hover{
    background-color: var(--primary-hover);
}