#block_dropdown{
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    align-items: flex-start;
    width: 15vw;
}

#block_dropdown ul{
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    align-items: flex-start;
    width: 15vw;
    box-sizing: border-box;
    padding: 0%;
}

.block{
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: inherit;
    display: flex;
    align-items: center;
    text-align: left;
    box-sizing: border-box;
}

.block > label{
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    display: block;
}

span {
    margin: 0 auto;
    font-weight: bold;
}