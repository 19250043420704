#main{
  display: flex;
  width: 100vw;
  height: 93vh;
  padding: 0;
  margin: 0;
  flex: 1;
  overflow: hidden;
}

