#sidenav{
    width: min-content;
    background: var(--primary-color);
    display: flex;
    flex-direction: row;
    gap: 2%;
    box-sizing: border-box;
    margin-right: 1vw;
}

.search{
    top: 10%;
    position: absolute;
    z-index: 1;
    left: 10%;
    display: flex;
    flex-direction: column;
}