.customSlider {
    width: 15vw;
    top: 10vh;
    right: 2vw;
    position: absolute;
    z-index: 1;
}
  
.customSlider-track {
    top: 8px;
    height: 4px;
    background: var(--secondary-color);
}

.customSlider-track.customSlider-track-0 {
    background: var(--secondary-hover);
}

.customSlider-thumb {
    cursor: pointer;
    /*color for the thumb */
    background: var(--alt-color);
    /* shape of the thumb: circle */
    width: 20px;
    height: 20px;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;
}

.customSlider-thumb:hover {
    box-shadow: 0 0 0 8px var(--alt-hover);
}