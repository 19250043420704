#cy{
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    flex: 3;
    margin: 0;
}

.modalButton.modalCloseButton{
    margin-right: auto;
    width: fit-content;
    border-color: red;
}

.selectLabel{
    text-align: center;
}

.blocksDiv{
    display: flex;
    flex-direction: column;
}

.blockButton{
    margin-top: 3%;
}

.modalButton{
    border: solid 1px var(--alt-color);
    background: transparent;
    border-radius: 5px;
    padding: 1%;
}

.modalButton:hover{
    cursor: pointer;
    background-color: var(--primary-hover);
}