.box{
    margin: 0;
    padding: 2%;
    border-radius: 15px;
    box-shadow: 0 0 .75em var(--secondary-color);
    background-color: var(--primary-color);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2%;
    height: 66vh;
    width: 1%;
}

.button{
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    flex: 1;
    height: fit-content;
    border-radius: 5px;
    padding: 1%;
    font-size: 1vw;
}

.button:hover{
    background-color: var(--primary-hover);
    cursor: pointer;
}

.button:focus{
    background-color: var(--primary-hover);
}

#commit:active{
    transform: scale(0.98);
}

.buttons{
    display: flex;
    flex-direction: column;
    gap: 8%;
    box-sizing: border-box;
    justify-content: center;
    width: 110%;
    align-items: center;
}

.clicked{
    background-color: var(--primary-hover);
}

.content{
    border: solid var(--secondary-color) 1px;
    width: 100%;
    height: 50%;
    margin: 0;
    flex: 1;
    border-radius: 15px;
    justify-content: center;
    overflow-y: auto;
    box-sizing: border-box;
    resize: none;
    overflow-wrap: break-word;
}

.top_row, .bottom_row{
    display: flex;
    gap: 4%;
    align-items: center;
    justify-content: center;
}